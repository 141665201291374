<template>
  <div>
    <v-sheet class="wsRoundedHalf pa-6 mt-6">

      <v-chip class="mb-5 px-6" outlined :color="tag.color || wsACCENT" v-for="(tag,i) in entityData.tags" :key="i + 'tag'">
        {{ tag.name }}
      </v-chip>

      <!-- Header and Save Button-->
      <div class="d-flex align-center justify-space-between">
        <h3 class="font-weight-medium" v-if="!SM" style="line-height: 1.2"> {{ $t('ProfileInformation') }}: </h3>
        <h5 v-if="SM" class="font-weight-medium" style="line-height: 1.2"> {{ $t('ProfileInformation') }}: </h5>
        <v-btn @click="saveChanges"
               rounded
               :outlined="!SM && !edit"
               :dark="edit"
               :text="SM"
               :color="edit ? wsATTENTION : wsACCENT"  class="noCaps">
          <v-icon v-if="!edit">mdi-pencil</v-icon>
          {{ !edit ? $t('Edit') : $t('Save') }}
        </v-btn>
      </div>


      <!-- Default Info  -->
      <v-slide-x-transition mode="out-in">
        <!-- Edit Client Profile -->
        <div key="edit" v-if="edit">
          <v-row class="mt-6">
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <ws-text-field
                  v-model="entityData.firstname"
                  :label="$t('Firstname') "
                  :placeholder="$t('Firstname')"/>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <ws-text-field
                  v-model="entityData.lastname"
                  :label="$t('Lastname')"
                  :placeholder="$t('Lastname')"/>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <ws-text-field
                  v-model="entityData.email"
                  :label="$t('Email')"
                  @input="error.email = false"
                  :error="error.email"
                  :placeholder="$t('Email')"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <ws-phone-input v-model="entityData.phone"
                              :label="$t('Phone')"
                              @input="error.phone = false"
                              :error="error.phone"
                              :placeholder="$t('Phone')"/>
            </v-col>
          </v-row>
          <v-row class="mt-n3">
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <ws-date-picker v-model="entityData.birthday" :label="$t('Birthday')" :placeholder="$t('Birthday')" />
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <ws-select
                  v-model="entityData.language"
                  :items="languagesSelect"
                  :label="$t('Language')"  />
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <ws-select
                  v-model="entityData.country"
                  :items="COUNTRIES_SELECT"
                  autocomplete
                  :label="$t('Country')"  />
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <ws-text-field
                  v-model="entityData.city"
                  :items="languagesSelect"
                  :label="$t('City')"  />
            </v-col>
          </v-row>
          <div class="d-flex align-end mt-6">
            <ws-text-field
                v-if="displayChangePassword"
                v-model="newPassword"
                :items="languagesSelect"
                :label="$t('NewPassword')"
                class="mr-2"
            />
            <v-btn height="40"  @click="displayChangePassword = !displayChangePassword ; newPassword = null" outlined class="noCaps "  :color="wsACCENT">
              {{ displayChangePassword ? $t('Cancel') : $t('ChangePassword') }}
            </v-btn>

          </div>

        </div>
        <!-- Preview Client Profile -->
        <div key="preview" v-else>
          <h5 class="mt-5"><span class="font-weight-medium" :style="`color : ${wsACCENT}`">{{ $t('Firstname') }}</span> : {{ entityData.firstname }}</h5>
          <h5 class="mt-3"><span class="font-weight-medium" :style="`color : ${wsACCENT}`">{{ $t('Lastname') }}</span> : {{ entityData.lastname }}</h5>
          <h5 class="mt-3"><span class="font-weight-medium" :style="`color : ${wsACCENT}`">{{ $t('Email') }} </span>: {{ entityData.email }}</h5>
          <h5 class="mt-3"><span class="font-weight-medium" :style="`color : ${wsACCENT}`">{{ $t('Phone') }} </span>: {{ entityData.phone }}</h5>
          <h5 class="mt-3"><span class="font-weight-medium" :style="`color : ${wsACCENT}`">{{ $t('Birthday') }} </span>: {{ entityData.birthday }}</h5>
          <h5 class="mt-3"><span class="font-weight-medium" :style="`color : ${wsACCENT}`">{{ $t('Language') }} </span>: {{ entityData.language }}</h5>
          <h5 class="mt-3"><span class="font-weight-medium" :style="`color : ${wsACCENT}`">{{ $t('Country') }} </span>: {{ entityData.country }}</h5>
          <h5 class="mt-3"><span class="font-weight-medium" :style="`color : ${wsACCENT}`">{{ $t('City') }} </span>: {{ entityData.city }}</h5>
        </div>
      </v-slide-x-transition>


      <h3 class="font-weight-medium mt-6"> {{ $t('CustomFields') }}: </h3>
      <!-- Custom Fields   -->
      <v-slide-x-transition mode="out-in">
        <!-- Edit Client Profile -->
        <div key="edit_custom" v-if="edit">
          <v-row class="mt-5">
            <template v-for="item in customFields " >
              <v-col cols="6" :key="item.id + 'field'">
                <div v-if="['link','text'].includes(item.type) " >
                  <ws-text-field
                      :prepend-inner-icon="getIcon(item.type)"
                      v-model="entityData.custom_fields[item.id]"
                      :label="item.name"
                  />
                </div>
                <div v-if="item.type === 'select'" :key="item.id">
                  <ws-select
                      :prepend-inner-icon="getIcon(item.type)"
                      v-model="entityData.custom_fields[item.id]"
                      :items="item.items"
                      :label="item.name"
                      :autocomplete="item.items.length > 7"

                  />
                </div>
                <div v-if="item.type === 'boolean'" :key="item.id">
                  <ws-switch
                      v-model="entityData.custom_fields[item.id]"
                      :label="item.name"
                  />
                </div>
              </v-col>
            </template>
          </v-row>
          <!-- Add new Custom field -->
          <v-btn
              v-if="edit" outlined
              @click="openNewCustomField" :color="wsACCENT"  class="noCaps mt-9">
            <v-icon>mdi-plus</v-icon>
            {{ $t('AddCustomField') }}
          </v-btn>
        </div>
        <!-- Preview Client Profile -->
        <div  key="preview_custom" v-else>
          <template v-for="item in customFields " >
            <h5 v-if="item.type === 'link'" class="mt-3" :key="item.id + 'field_preview'">
              <span class="font-weight-medium" :style="`color : ${wsACCENT}`">{{ item.name}} </span>:
              <a class="noUnderline linkHover"  target="_blank"  :style="`color : ${wsACCENT}`" :href="entityData.custom_fields[item.id]">
                {{ entityData.custom_fields[item.id] }}
              </a>
              <v-icon small :color="wsACCENT">mdi-open-in-new</v-icon>
            </h5>

            <h5 v-else class="mt-3" :key="item.id + 'field_preview'">
              <span class="font-weight-medium" :style="`color : ${wsACCENT}`">{{ item.name}} </span>: {{ entityData.custom_fields[item.id] }}
            </h5>
          </template>

        </div>
      </v-slide-x-transition>

    </v-sheet>

    <ws-dialog
        v-model="displayCustomFieldDialog"
        :title="$t('AddCustomField')"
        @save="addCustomField"
        :width="customFieldEntityData.type === 'select' ? 900 : 600"

    >

      <div class="pa-1">
        <ws-text-field
            v-model="customFieldEntityData.name"
            :label="$t('Name')"
            :placeholder="$t('EnterCustomFieldName')"
            :error="fieldNameError"
            @input="fieldNameError = false"
        />
        <ws-select
            class="mt-3"
            v-model="customFieldEntityData.type"
            :items="customFieldsTypes"
            :placeholder="$t('ChooseCustomFieldType')"
            :label="$t('Type')"
            :error="fieldTypeError"
            @input="fieldTypeError = false"
        />

        <v-expand-transition>
          <div v-if="customFieldEntityData.type === 'select'">
            <div class="d-flex justify-space-between align-center">
              <h5 class="mt-5">{{ $t('CustomFieldSelectItems') }}</h5>
              <!-- <ws-switch :label="$t('differentValues')" v-model="customFieldEntityData.select_items_different"></ws-switch>-->
            </div>
            <v-row class="pt-5" >
              <v-col>
                <v-sheet max-height="400" class="overflow-y-auto pb-1">
                  <ws-text-field
                      v-model="customFieldEntityData.select_items_text"
                      :label="$t('Name')"
                      :placeholder="$t('EnterCustomFieldItems')"
                      area
                      lined
                  />
                </v-sheet>

              </v-col>
              <v-col v-if="customFieldEntityData.select_items_different">
                <v-sheet max-height="400" class="overflow-y-auto">
                  <ws-text-field
                      v-model="customFieldEntityData.select_items_value"
                      :label="$t('Value')"
                      :placeholder="$t('EnterCustomFieldValue')"
                      area
                      lined
                  />
                </v-sheet>
              </v-col>
            </v-row>
          </div>

        </v-expand-transition>

        <ws-switch
            class="mt-5"
            v-model="customFieldEntityData.is_visible"
            :placeholder="$t('CustomFieldVisibleToClient')"
            :tooltip="$t('CustomFieldVisibleToClientTooltip')"
        />

      </div>

    </ws-dialog>

  </div>

</template>

<script>
import {mapActions} from "vuex";
import languagesList from "@/mixins/languagesList";

export default {
  name: "clientProfile",
  mixins : [languagesList],
  props : {
    uuid : {
      type : String,
      default : ''
    },
  },
  data() {
    return {
      displayChangePassword : false,
      edit : false,
      entityData : {},
      newPassword : null,
      customFieldEntityData : {},
      displayCustomFieldDialog : false,
      differentSelectValues : false,
      customFields : [],
      error : {
        email : false,
        phone : false
      },
      languagesSelect : [
        { text : 'UA' , value : 'ua' },
        { text : 'RU' , value : 'ru' },
        { text : 'EN' , value : 'en' }
      ],
      fieldNameError : false,
      fieldTypeError : false,
    }
  },
  computed : {
    customFieldsTypes() {
      return [
        { text : this.$t('Text')           ,   value : 'text'       },
        { text : this.$t('ChooseFromList') ,   value : 'select'     },
        // { text : this.$t('TextArea')    ,   value : 'textarea'   },
        // { text : this.$t('Email')       ,   value : 'email'      },
        { text : this.$t('Link')           ,   value : 'link'       },
        // { text : this.$t('Phone')       ,   value : 'phone'      },
        // { text : this.$t('Number')      ,   value : 'number'     },
        { text : this.$t('BooleanType')    ,   value : 'boolean'    },

      ]
    }
  },
  methods : {
    ...mapActions( 'crmSystem', [
        'GET_CLIENT_PROFILE',
        'EDIT_CLIENT_PROFILE',
        'ADD_CUSTOM_FIELD'
    ]),

    openNewCustomField() {
      this.displayCustomFieldDialog = true
      this.customFieldEntityData = {}
    },
    async addCustomField() {

      if ( !this.customFieldEntityData.name ) {
        this.fieldNameError = true
        this.notify(this.$t('EnterCustomFieldName') , 'warning')
        return
      }
      if ( !this.customFieldEntityData.type ) {
        this.fieldTypeError = true
        this.notify(this.$t('EnterCustomFieldType') , 'warning')
        return
      }

      let result = await this.ADD_CUSTOM_FIELD(this.customFieldEntityData)
      if ( !result ) {
        return
      }
      this.customFields.push(result)
      this.displayCustomFieldDialog = false
      this.notify(this.$t('ChangesSaved'))
    },

    async saveChanges() {
      if ( !this.edit ) {
        this.displayChangePassword = false
        this.edit = true
        return
      }
      if ( this.newPassword && this.displayChangePassword ) {
        this.entityData.password = this.newPassword
      }
     let result = await this.EDIT_CLIENT_PROFILE(this.entityData)
      if ( !result ) {
        this.notify(this.$store.state.ajax.error)
        switch(this.$store.state.ajax.error) {
            case "User phone already exists" :
              this.error.phone = this.$t(this.$store.state.ajax.error)
              this.notify(this.$t(this.$store.state.ajax.error))
              break;
            case "User email already exists" :
              this.error.email = this.$t(this.$store.state.ajax.error)
              this.notify(this.$t(this.$store.state.ajax.error))
              break;
            case "Phone is business owner"   :
              this.error.phone = this.$t('User phone already exists')
              this.notify(this.$t('User phone already exists'))
              break;
          case "Email is business owner"   :
            this.error.email = this.$t('User email already exists')
            this.notify(this.$t('User email already exists'))
            break;
        }
        return
      }
      this.notify(this.$t('ChangesSaved'))
      this.edit = false
      this.$store.state.crmSystem.triggerClientCard++
    },
    getIcon(type) {
      let icons = {
        select : 'mdi-form-select',
        text : 'mdi-form-textbox',
        boolean : 'mdi-order-bool-ascending',
        link : 'mdi-link-variant'
      }
      return icons[type] ? icons[type] : 'mdi-form-textbox'
    },


    async initPage() {
      this.displayChangePassword = false
      let result = await this.GET_CLIENT_PROFILE(this.uuid)
      if ( !result || result === true ) {
        return
      }
      this.entityData = result.user
      this.customFields = result.custom_fields
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>